import React from 'react';
import Services from '../../components/Services/Services';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';

const ServicePage = () => {
    const data = {
        img: pic,
        title: "Our Services",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <Services/>
    </div>
  )
}

export default ServicePage
