import React from 'react';
import Slider from '../../components/Slider/Slider';
import Contact from '../../components/Contact/Contact';
import Services from '../../components/Services/Services';
import About from '../../components/About/About';


const Home = () => {
  return (
    <div>
      <Slider/> 
      <About/>
      <Services/>
      <Contact/>
    </div>
  )
}

export default Home
