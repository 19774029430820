import React from 'react';
import ServiceCard from './ServiceCard';

const Services = () => {

    const data = [
        {
            id:1,
            title: "Cartographic Modeling Services",
            description: "At the heart of our expertise lies our ability to collect, analyze, and interpret spatial data from diverse sources, tailoring the information to your specific requirements. Whether it's survey data, satellite imagery, aerial photographs, statistical data, or GIS databases, our team is equipped to handle it all",
            url:"/catography",
            icon:"fa-home",
        },
        {
            id:2,
            title: "Land Surveys and Title Processing",
            description: "From engineering surveys and topographic surveys to cadastral surveys and land subdivision, we handle the collection and presentation of spatial data with precision. Additionally, our expertise extends to title processing and the establishment of ground controls.",
            url:"/landSurveys",
            icon:"fa-globe",
        },
        {
            id:3,
            title: "Geospatial Analysis",
            description: "Leveraging the power of Geographic Information Systems (GIS), our company transforms complex spatial data into actionable insights. From mapping and visualization to modeling and simulation, spatial and network analysis, and spatial database designing and building, we offer a range of geospatial analysis services",
            url:"/geospatial",
            icon:"fa-database",
        },
        {
            id:4,
            title: "Remote Sensing Services",
            description: "Our offerings include ortho-rectification, satellite image processing, image classification, and land cover mapping, enabling organizations to harness the benefits of Earth Observation imageries across various sectors.",
            url:"/remoteSensing",
            icon:"fa-star",
        },
        {
            id:5,
            title: "Color Map Printing Services",
            description: "We offer high-quality printing techniques, producing maps in white format and full color up to A0 size. Our printing services cover engineering drawings, architectural drawings, topographical maps, cadastral maps, and thematic maps.",
            url:"/colourMap",
            icon:"fa-hourglass",
        },
        {
            id:6,
            title: "Research and Training",
            description: "We offer research, and training in the following thematic areas; Cartographic Map Design, Satellite Image Interpretation and Analysis, and Geographical Information System (GIS) Analysis.  We also offer specific software training using non-proprietary software such as Qgis.",
            url:"/research",
            icon:"fa-gears",
        }
    ]
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
          <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span></h1>
        </div>
        <div className="row g-4">
            { data?.map((items) => (
                    <ServiceCard key={items.id} {...items} />
                
            ))}    
        </div>
      </div>
    </div>
  );
}

export default Services;
