import React, { useState } from 'react';
import './Slider.scss';

import pic_1 from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/carousel-2.jpg';
import pic_3 from '../../static/img/carousel-1.jpg';
import pic_4 from '../../static/img/carousel-4.jpg';
import pic_5 from '../../static/img/carousel-5.jpg';

const Slider = () => {

    const carouselData = [
        {
          imageSrc: pic_1,
          title: 'Cartographic modeling',
          subtitle: 'Map Design and Layout, Thematic Mapping'
        },
        {
          imageSrc: pic_2,
          title: 'Land Surveys and Title',
          subtitle: 'Land Surveys, Topographic and Site Surveys'
        },
        {
            imageSrc: pic_3,
            title: 'Geospatial Analysis',
            subtitle: 'Geographical Information System (GIS)',
        },
        {
            imageSrc: pic_4,
            title: 'Remote sensing',
            subtitle: 'Satellite Image processing and analysis',
        },
        {
            imageSrc: pic_5,
            title: 'Colour Map Printing',
            subtitle: 'Engineering & Architectural drawings, Cadastral maps',
        }
      ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1));
    };
  return (
    <div className="container-fluid p-0 mb-5">
      <div id="header-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div className="carousel-inner">
          {carouselData.map((item, index) => (
            <div key={index} className={`carousel-item ${index === 1 ? 'active' : ''}`} style={{ height: '510px' }}>
              <img className="w-100" src={item.imageSrc} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: '1000px' }}>
                  <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">{item.title}</h6>
                  <h1 className="display-3 text-white mb-4 animated slideInDown">{item.subtitle}</h1>
                  <a href="/services" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Our Services</a>
                  <a href="/contact" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev" onClick={handlePrev}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next" onClick={handleNext}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Slider;
