import './App.scss';
import './App.css';

import { createBrowserRouter, RouterProvider,Outlet, } from "react-router-dom";
import Topbar from './components/Topbar/Topbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Contact_us from './pages/Contact/Contact_us';
import Services from './pages/Service/Service';
import Aboutus from './pages/AboutUs/AboutUs';
import Catography from './pages/Service/Catograghy';
import ColourMap from './pages/Service/ColourMap';
import Research from './pages/Service/Research';
import Geospatial from './pages/Service/Geospatial';
import LandSurveys from './pages/Service/LandSurveys';
import RemoteSensing from './pages/Service/RemoteSensing';


const Layout = () =>{
  return (
    <div className="app">
      <Topbar/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children:[
        {
          path: "/",
          element: <Home/>
        },
        {
          path: "/about",
          element: <Aboutus/>
        },
        {
          path: "/contact",
          element: <Contact_us/>
        },
        {
          path: "/services",
          element: <Services/>
        },
        {
          path: "/catography",
          element: <Catography/>
        },
        {
          path: "/colourMap",
          element: <ColourMap/>
        },
        {
          path: "/geospatial",
          element: <Geospatial/>
        },
        {
          path: "/landSurveys",
          element: <LandSurveys/>
        },
        {
          path: "/remoteSensing",
          element: <RemoteSensing/>
        },
        {
          path: "/research",
          element: <Research/>
        }
      ]
    },
  ])


function App() {
  return (
    <div>
    <RouterProvider router={router}/>
  </div>
  );
}

export default App;
