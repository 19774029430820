import React from 'react';
import logo from '../../logo.png';


function Topbar() {
  return (
    <div className="container-fluid bg-dark px-3">
      <div className="row gx-0">
        <div className="col-lg-4 bg-dark d-none d-lg-block">
          <a href="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
            <img height='120px' src={logo} alt='logo'/>
          </a>
        </div>
        <div className="col-lg-8">
          <div className="row gx-0 bg-white d-none d-lg-flex">
            <div className="col-lg-10 px-3 text-start">
              <div className="h-100 d-inline-flex align-items-center py-2 me-4">
                <i className="fa fa-envelope text-primary me-2"></i>
                <p className="mb-0">mokorosiro@gmail.com | sbaariu09@gmail.com</p>
              </div>
              <div className="h-100 d-inline-flex align-items-center py-2">
                <i className="fa fa-phone-alt text-primary me-2"></i>
                <p className="mb-0">+254 722 302 805 | +254 722 398 861</p>
              </div>
            </div>
            <div className="col-lg-2 px-3 text-end">
              <div className="d-inline-flex align-items-center py-2">
                <a className="me-3" href="https://facebook.com/people/Geospacetechke/61557176240407/"><i className="fab fa-facebook-f"></i></a>
                <a className="me-3" href="https://twitter.com/geospacetechke"><i className="fab fa-twitter"></i></a>
                <a className="me-3" href="https://www.linkedin.com/company/geospacetechke/"><i className="fab fa-linkedin-in"></i></a>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
            <a href="/" className="navbar-brand d-block d-lg-none">
            <img height='100px' src={logo} alt='logo'/>
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
              <div className="navbar-nav mr-auto py-0">
                <a href="/" className="nav-item nav-link active">Home</a>
                <a href="/about" className="nav-item nav-link">About</a>
                <div className="nav-item dropdown">
                  <a href="/services" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Service</a>
                  <div className="dropdown-menu rounded-0 m-0">
                    <a href="/catography" className="dropdown-item">Cartographic modeling</a>
                    <a href="/landSurveys" className="dropdown-item">Land Surveys & Title processing</a>
                    <a href="/geospatial" className="dropdown-item">Geospatial Analysis</a>
                    <a href="/remoteSensing" className="dropdown-item">Remote Sensing</a>
                    <a href="/colourMap" className="dropdown-item">Colour Map Printing</a>
                    <a href="/research" className="dropdown-item">Research, and Training</a>
                  </div>
                </div>
                <a href="/contact" className="nav-item nav-link">Contact</a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
