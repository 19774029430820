import React from 'react';


const ServiceItem = (data) => {
  return (
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <a className="service-item rounded" href={data.url}>
        <div className="service-icon bg-transparent border rounded p-1">
          <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
            <i className={`fa ${data.icon} fa-2x text-primary`}></i>
          </div>
        </div>
        <h5 className="mb-3">{data.title}</h5>
        <p className="text-body mb-0">
          {data.description}
        </p>
      </a>
    </div>
  );
};

export default ServiceItem;
