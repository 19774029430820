import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/print-2.jpg'

const ColourMap = () => {
    const data = {
        img: pic,
        title: "Colour Map Printing Services",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-8">
            <h2 className="mb-4">Colour Map Printing Services</h2>
              <p className="mb-4">  Maps are printed details of large geographical areas hence paper sizes required for map printing are larger than regular printing papers. Map printing requires specialized knowledge and equipment. </p> 
              <p className="mb-4"> At Geospacetech, our technicians will assist you with any type of map customized and printed to suit your application such as route planning, environmental management, disaster risks, and any special event.  </p>
              <p className="mb-4"> We offer map printing services, ensuring that physical copies of maps are created with high-quality printing techniques using fit-for-purpose media with an emphasis on superior graphic quality. We print in white format and full color up to A0 size.  </p>
              <p className="mb-4">  Services offered include:</p>
              <ol>
                <li> <span className="text-primary"> Engineering drawings. </span> 
                </li>
                <li> <span className="text-primary"> Architectural drawings. </span> 
                </li>
                <li> <span className="text-primary"> Topographical maps. </span> 
                </li>
                <li> <span className="text-primary"> Cadastral maps. </span> 
                </li>   
                <li> <span className="text-primary"> Thematic maps. </span> 
                </li>          
              </ol>
            </div>
            <div className="col-lg-4">
              <img className="img-fluid rounded zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />    
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ColourMap
