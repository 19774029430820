import React from 'react';

function Footer() {
  return (
    <div className="container-fluid bg-dark text-light footer wow fadeIn" data-wow-delay="0.1s">
      <div className="px-3">
        <div className="row g-5">
          <div className="col-md-6 col-lg-4">
            <div className="bg-primary rounded p-4">
              <a href="index.html"><h1 className="text-dark text-uppercase mb-3">GEOSPACETECH</h1></a>
              <p className="text-white mb-0">Our Consultancy Services range includes Land Surveys and Title processing; Cartographic modelling services; Geospatial analysis; Remote sensing services; Map printing and dissemination, and research and training. 
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <h6 className="section-title text-start text-primary text-uppercase mb-4">Contact</h6>
            <p className="mb-2 text-darker"><i className="fa fa-map-marker-alt me-3 text-primary"></i>Mizpah House, 1st Floor, Room No.6 <br/> Kahawa Sukari, Opp Quick Matt Supermkt</p>
            <p className="mb-2 text-darker"><i className="fa fa-phone-alt me-3 text-primary"></i>+254 722 302 805 | +254 722 398 861</p>
            <p className="mb-2 text-darker"><i className="fa fa-envelope me-3 text-primary"></i> mokorosiro@gmail.com | sbaariu09@gmail.com</p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="https://twitter.com/geospacetechke"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://facebook.com/people/Geospacetechke/61557176240407/"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/geospacetechke/"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="row gy-5 g-4">
              <div className="col-md-6">
                <h6 className="section-title text-start text-primary text-uppercase mb-4">Company</h6>
                <a className="btn btn-link" href="/">Home</a>
                <a className="btn btn-link" href="/about">About Us</a>
                <a className="btn btn-link" href="/services">Our Service</a>
                <a className="btn btn-link" href="/contact">Contact Us</a>
                <a className="btn btn-link" href="/contact">Support</a>
              </div>
              <div className="col-md-6">
                <h6 className="section-title text-start text-primary text-uppercase mb-4">Services</h6>
                <a className="btn btn-link" href="/catography">Cartographic modeling</a>
                <a className="btn btn-link" href="/landSurveys">Land Surveys & Title processing</a>
                <a className="btn btn-link" href="/geospatial">Geospatial Analysis</a>
                <a className="btn btn-link" href="/remoteSensing">Remote Sensing</a>
                <a className="btn btn-link" href="/colourMap">Colour Map Printing </a>
                <a className="btn btn-link" href="/research">Research, and Training</a>
              </div>
            </div>
          </div>
        </div>
        </div>
      <div className="px-3">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-darker text-md-start mb-3 mb-md-0">
              &copy; <a className="border-bottom" href="#">GeoSpaceTech</a>; All Right Reserved. <br/>   
            </div>
            <div className="col-md-6 text text-darker text-md-end mb-3 mb-md-0">
            Designed By: <a className="border-bottom" href="https://sitycommunocations.co.ke">Sity Communications</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
