import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/research.jpg'

const Research = () => {
    const data = {
        img: pic,
        title: "Research and Training",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-8">
            <h2 className="mb-4">Research and Training</h2>
              <p className="mb-4">  We offer research, and training in the following thematic areas; Cartographic Map Design, Satellite Image Interpretation and Analysis, and Geographical Information System (GIS) Analysis.  We also offer specific software training using non-proprietary software such as Qgis.  </p> 
              <p className="mb-4"> Our customers come from various industries, including urban planning, environmental management, utilities, disaster response, academia, health, and more. Cartography plays a crucial role in visually representing and understanding spatial information for decision-making and communication purposes.  </p>
              
            </div>
            <div className="col-lg-4">
              <img className="img-fluid rounded zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />    
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Research
