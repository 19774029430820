import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/catography-1.jpg'

const Catography = () => {
    const data = {
        img: pic,
        title: "Cartographic Modeling Services",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-8">
            <h2 className="mb-4">Cartographic Modeling Services</h2>
              <p className="mb-4"> At GeospaceTech, we work with you to create the maps you imagine through the provision of cartographic services from concept through delivery in the most efficient and cost-effective manner.  </p> 
              <p className="mb-4"> They include: </p>
              <ol>
                <li> <span className="text-primary">Data collection and analysis:</span> We collect, analyze, interpret, and integrate spatial data from various sources according to customer needs. 
                  This may include survey data, satellite imagery, aerial photographs, statistical data, and data obtained from existing GIS Databases and hard-copy maps.
                </li>
                <li> <span className="text-primary"> Map Design and Layout: </span> 
                  The aspects of map design, including the choice of appropriate colors, symbols, projection, and scales are our specialty.
                  Effective map design guarantees clarity, readability, and the communication of relevant information to the desired audience. 
                </li>
                <li> <span className="text-primary"> Thematic Mapping: </span> 
                  Our specialized cartographic services focus on creating thematic maps that emphasize specific topics of interest, such as mapping environmental conditions, demographic trends, facility locations, and route mapping.
                </li>
                <li> <span className="text-primary"> Custom Mapping: </span> 
                  Custom Cartography Services include dedicated maps to support businesses, research projects, or government agencies. This could involve highlighting a particular theme using proprietary data to emphasize the client's needs. 
                </li>            
              </ol>
            </div>
            <div className="col-lg-4">
              <img className="img-fluid rounded zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />    
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Catography
