import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-5.jpg';
import About from '../../components/About/About';

const AboutUs = () => {
    const data = {
        img: pic,
        title: "About Us",
        page: "About"
    }

  return (
    <div>
      <Header {...data}/>
      <About/>
    </div>
  )
}

export default AboutUs
