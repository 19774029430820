import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/remote-1.jpg'

const RemoteSensing= () => {
    const data = {
        img: pic,
        title: "Remote Sensing Services",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-8">
            <h2 className="mb-4"> Remote Sensing Services</h2>
              <p className="mb-4"> At GeospaceTech land surveying and mapping consultants, we offer extensive satellite remote sensing, photogrammetry, and Lidar services that will support your organization in utilizing greater benefits from Earth Observation imageries. Through a variety of techniques, unseen information can be revealed that can support decision-making in sectors including Health, agriculture, environment, land, water, Disaster management, coastal management, weather, and climate among others.
  </p> 
              <p className="mb-4"> Services Offered: </p>
              <ol>
                <li> <span className="text-primary">Ortho-rectification.</span> 
                </li>
                <li> <span className="text-primary"> Satellite Image processing (color balancing, mosaicking, and compression), and analysis . </span> 
                </li>
                <li> <span className="text-primary"> Image Classification. </span> 
                </li>
                <li> <span className="text-primary"> Land cover mapping and change analysis. </span>
                </li>   
              </ol>
            </div>
            <div className="col-lg-4">
              <img className="img-fluid rounded zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />    
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RemoteSensing
