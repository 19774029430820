import React from 'react';
import Contact from '../../components/Contact/Contact';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-1.jpg';

const ContactUs = () => {
    const data = {
        img: pic,
        title: "Contact Us",
        page: "Contact Us"
    }

  return (
    <div>
      <Header {...data}/>
      <Contact/>
    </div>
  )
}

export default ContactUs
