import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/about-2.jpg'

const LandSurveys = () => {
    const data = {
        img: pic,
        title: "Land Surveys and Title Processing",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-8">
            <h2 className="mb-4"> Land Surveys and Title Processing</h2>
              <p className="mb-4"> Land surveying entails the collection, processing, and presentation of spatial data for land information management services mainly to establish boundaries for ownership and locations for titling and development planning.   </p> 
              <p className="mb-4"> We Offer: </p>
              <ol>
                <li> <span className="text-primary">Engineering Surveys.</span> 
                </li>
                <li> <span className="text-primary"> Topographic and Site Surveys. </span> 
                </li>
                <li> <span className="text-primary"> Cadastral Surveys. </span> 
                </li>
                <li> <span className="text-primary"> Land Subdivision and amalgamation. </span>
                </li>   
                <li> <span className="text-primary"> Title processing. </span>
                </li>  
                <li> <span className="text-primary"> Establishment of ground controls. </span>
                </li>           
              </ol>
            </div>
            <div className="col-lg-4">
              <img className="img-fluid rounded zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />    
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandSurveys
