import React from 'react';
import Header from '../../components/Header/Header';
import pic from '../../static/img/carousel-3.jpg';
import pic_2 from '../../static/img/about-4.jpg'

const Geospatial = () => {
    const data = {
        img: pic,
        title: "Geospatial Analysis",
        page: "Services"
    }

  return (
    <div>
      <Header {...data}/>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-8">
            <h2 className="mb-4">Geospatial Analysis</h2>
              <p className="mb-4">  Geographical Information System (GIS) has become critical in decision making, planning and management of complex spatial data. Rooted in the science of geography, GIS integrates many types of data; it analyses spatial location and categorises layers of information into visualizations using maps and 3D scenes. 
              We develop several market-oriented geospatial products and services in the fields of GIS including and not limited to GIS mapping and visualization, GIS modelling and simulation, spatial and network analysis and Spatial Database Designing and Building. Our company provides geospatial analysis, which involves examining spatial patterns, relationships, and trends in the data.   </p> 
    
              
            </div>
            <div className="col-lg-4">
              <img className="img-fluid rounded zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />    
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Geospatial
