import React from 'react';

const Header = (data) => {
  return (
    <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: `url(${data.img})` }}>
      <div className="container-fluid page-header-inner py-5">
        <div className="container text-center pb-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">{data.title}</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center text-uppercase">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">{data.page}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
