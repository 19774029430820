import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://sendmail.geospacetech.co.ke/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Email sent successfully, update success message and clear the form
        setSuccessMessage('Email sent successfully');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      } else {
        // Handle error when the email sending fails
        console.error('Error sending email');
        setSuccessMessage('Error sending email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setSuccessMessage('Error sending email. Please try again.');
    }
  };

  return (
    <div className="container-xl py-3">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary text-uppercase">Contact Us</h6>
          <h1 className="mb-5"><span className="text-primary text-uppercase">Contact</span> For Any Query</h1>
        </div>
        <div className="row g-4">
          <div className="col-12">
            <div className="row gy-4">
              <div className="col-md-4">
                <h6 className="section-title text-start text-primary text-uppercase">Booking</h6>
                <p><i className="fa fa-envelope-open text-primary me-2"></i>sbaariu09@gmail.com | mokorosiro@gmail.com</p>
              </div>
              <div className="col-md-4">
                <h6 className="section-title text-start text-primary text-uppercase">General</h6>
                <p><i className="fa fa-envelope-open text-primary me-2"></i>info@geospacetech.co.ke</p>
              </div>
              <div className="col-md-4">
                <h6 className="section-title text-start text-primary text-uppercase">Calls</h6>
                <p><i className="fa fa-phone text-primary me-2"></i>+254 722 398 861 | +254 722 302 805 </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10478.288300511129!2d36.92903816474143!3d-1.1913977775006672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f3fa20ca76fb3%3A0x1f0589302d8290be!2sMizpah%20House!5e0!3m2!1sen!2ske!4v1708800334777!5m2!1sen!2ske"
              width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-md-6">
            <div className="wow fadeInUp" data-wow-delay="0.2s">
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} />
                      <label htmlFor="subject">Subject</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: '150px' }} value={formData.message} onChange={handleChange}></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
