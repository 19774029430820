import React from 'react';
import pic_1 from '../../static/img/remote-2.jpg';
import pic_2 from '../../static/img/about-2.jpg';
import pic_3 from '../../static/img/catography-1.jpg';
import pic_4 from '../../static/img/print-2.jpg';

const About = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6">
            <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
            <h1 className="mb-4">Welcome to <span className="text-primary text-uppercase">GEOSPACETECH</span></h1>
            <p className="mb-4"> We are a Land Surveying and Mapping Company specializing in Land Surveys and Title processing; Cartography Services; Geospatial Analysis; Remote sensing Services; Large Format Map Scanning, Copying, Printing and Dissemination, and Research and Training. </p>
            <p className="mb-4"> We specialize in land surveys and title processing by providing precise spatial data for ownership boundaries and development planning. </p>
            <p className="mb-4"> Our Geospatial analysis services leverage cutting-edge GIS technology, offering insights through mapping, visualization, modeling, and spatial database design. In addition, our remote sensing expertise utilizes satellite imagery, photogrammetry, and Lidar techniques to support decision-making across various sectors. </p>
            <p className="mb-4"> Completing our comprehensive suite of offerings, our large format map scanning, photocopy, and printing services ensure high-quality physical copies of maps for diverse applications, from engineering and architectural drawings to topographical and thematic maps. Choose GeospaceTech for a seamless blend of innovation, commitment, and expertise in shaping the future of geospatial solutions. </p> 
            <div className="row g-3 pb-4">
              <a className="btn btn-primary py-3 px-5 mt-2" href="">Explore More</a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row g-3">
              <div className="col-6 text-end">
                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={pic_1} alt="About 1" />
              </div>
              <div className="col-6 text-start">
                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src={pic_2} alt="About 2" />
              </div>
              <div className="col-6 text-end">
                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.5s" src={pic_3} alt="About 3" />
              </div>
              <div className="col-6 text-start">
                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={pic_4} alt="About 4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
